import React, { useState, useContext, useCallback } from 'react';

const AutoSaveContext = React.createContext();

const AutoSaveProvider = (props) => {
    const [autoSaveFunctions, setAutoSaveFunctions] = useState(null);

    const registerAutoSave = useCallback((fn) => {
        setAutoSaveFunctions(() => fn);
    }, []);

    const unRegisterAutoSave = useCallback(() => {
        setAutoSaveFunctions(null);
    }, []);

    const executeAutoSave = useCallback(async () => {
        if (autoSaveFunctions) {
            await autoSaveFunctions();
        }
    }, [autoSaveFunctions]);

    const autoSaveMethods = { registerAutoSave, unRegisterAutoSave, executeAutoSave };

    return (
        <AutoSaveContext.Provider value={autoSaveMethods}>
            {props.children}
        </AutoSaveContext.Provider>
    );
};

const useAutoSaveContextProvider = () => {
    return useContext(AutoSaveContext);
};

export {
    AutoSaveProvider,
    useAutoSaveContextProvider
};