import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faFilter, faGripLinesVertical, faArrowUp, faArrowDown, faArrowLeft, faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useLocale } from 'services/localization/localizationContextProvider';
import { useScreenDetect } from 'hooks/useScreenDetect';
import { useForm, FormProvider } from 'react-hook-form';
import { fetch, put, httpDelete } from 'services/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import RingiPage, { PageContent, PageHeader } from 'controls/RingiPage/RingiPage';
import RingiList from 'pages/Ringis/components/RingiList';
import classNames from 'classnames';
import { FILTER_TYPE, ROUTE_MAP, RINGI_STATUS, RINGI_LIST_FILTER_OPTIONS } from 'constants/enums';
import { useUserContextProvider } from 'providers/userContextProvider';
import { ROUTES } from 'constants/constants';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import { LocalDateInputFormat, LocalDateRangeStartAddUtcOffset, LocalDateRangeEndAddUtcOffset } from 'services/localization/dateTimeService';
import FilterFlyoutSearchList from 'pages/Ringis/components/Flyouts/FilterFlyoutSearchList';
import 'pages/Ringis/ViewRingisPage.scss';

export default function ViewRingisPage({ emptyMessage, fetchRingisUrl, fetchColumnsUrl, fetchSelectedColumnsUrl, defaultSelection }) {

    const history = useHistory();
    const defaultFilterValue = '';
    const defaultFilterObject = { id: defaultFilterValue, name: defaultFilterValue };

    const localizedViewRingis = useLocale().pages.view_ringis;
    const localizedSearchRingis = useLocale().pages.view_ringis.search_ringis;
    const localizedFilterFlyout = useLocale().pages.view_ringis.filter_flyout;
    const localizedColumnsFlyout = useLocale().pages.view_ringis.columns_flyout;
    const { userDefaultLandingPage, refreshLandingPage } = useUserContextProvider();
    const RINGI_PAGE_COUNT = 50;
    const { isMobile, isDesktop } = useScreenDetect();
    const [columns, setColumns] = useState([]);
    const [manipulatedColumns, setManipulatedColumns] = useState([]);
    const [defaultColumns, setDefaultColumns] = useState([]);
    const [ringis, setRingis] = useState([]);
    const [hasMoreRingis, setHasMoreRingis] = useState(true);
    const [currentPageIsDefault, setCurrentPageIsDefault] = useState(false);
    const [ringiNextPage, setRingiNextPage] = useState(1);
    const [emptyMessageDisplayed, setEmptyMessageDisplayed] = useState();
    //Search fields
    const methods = useForm({ reValidateMode: 'onSubmit' });
    //Filter fields
    const [flyoutFilterIsVisible, setFlyoutFilterVisible] = useState(false);
    const [flyoutFilterStatusFieldIsVisible, setFlyoutFilterStatusFieldVisible] = useState(false);
    const [flyoutFilterInitiatorIsVisible, setFlyoutFilterInitiatorIsVisible] = useState(false);
    const [flyoutFilterCategoryIsVisible, setFlyoutFilterCategoryIsVisible] = useState(false);
    const [flyoutFilterTypeIsVisible, setFlyoutFilterTypeIsVisible] = useState(false);
    const [flyoutFilterCostCenterIsVisible, setFlyoutFilterCostCenterIsVisible] = useState(false);
    const [currentFilterApplied, setCurrentFilterApplied] = useState({});
    const [statusOptions, setStatusOptions] = useState([]);
    const [filterOptions, setFilterOptions] = useState({});
    const [statusFieldValue, setStatusFieldValue] = useState(defaultFilterValue);
    const [initiatorField, setInitiatorField] = useState(defaultFilterObject);
    const [categoryField, setCategoryField] = useState(defaultFilterObject);
    const [typeField, setTypeField] = useState(defaultFilterObject);
    const [costCenterField, setCostCenterField] = useState(defaultFilterObject);
    const [startDateField, setStartDateField] = useState(defaultFilterValue);
    const [endDateField, setEndDateField] = useState(defaultFilterValue);
    const [completedStartDateField, setCompletedStartDateField] = useState(defaultFilterValue);
    const [completedEndDateField, setCompletedEndDateField] = useState(defaultFilterValue);
    //Column fields
    const [flyoutColumnsIsVisible, setFlyoutColumnsVisible] = useState(false);
    const [flyoutRemovedColumnsIsVisible, setFlyoutRemovedColumnsVisible] = useState(false);
    const [columnOrderChanged, setColumnOrderChanged] = useState(false);
    const [isDefaultColumnState, setIsDefaultColumnState] = useState(true);

    const getViewTypeFromPage = useCallback(() => {
        if (history.location.pathname.includes(`/ringis/current`)) { return FILTER_TYPE.Current }
        else if (history.location.pathname.includes(`/ringis/drafts`)) { return FILTER_TYPE.Draft }
        else if (history.location.pathname.includes(`/ringis/submitted`)) { return FILTER_TYPE.Submitted }
        else if (history.location.pathname.includes(`/ringis/toApprove`)) { return FILTER_TYPE.ToApprove }
        else if (history.location.pathname.includes(`/ringis/toRead`)) { return FILTER_TYPE.ToRead }
        else if (history.location.pathname.includes(`/ringis/archived`)) { return FILTER_TYPE.Archived }
        else { throw `Unexpected history pathname: ${history.location.pathname}`; }

    }, [history.location.pathname]);

    useEffect(() => {
        setCurrentPageIsDefault(userDefaultLandingPage === ROUTE_MAP[defaultSelection]);
    }, [userDefaultLandingPage]);

    useEffect(() => {
        var page = getViewTypeFromPage();
        if (page === FILTER_TYPE.Current ||
            page === FILTER_TYPE.Submitted ||
            page === FILTER_TYPE.ToApprove ||
            page === FILTER_TYPE.ToRead ||
            page === FILTER_TYPE.Archived) {
            setStatusOptions([
                { name: localizedFilterFlyout.status.approved, value: RINGI_STATUS.Approved},
                { name: localizedFilterFlyout.status.denied, value: RINGI_STATUS.Denied},
                { name: localizedFilterFlyout.status.completed, value: RINGI_STATUS.Approved + ";" + RINGI_STATUS.Denied},
                { name: localizedFilterFlyout.status.pendingAll, value: RINGI_STATUS.Pending + ";" + RINGI_STATUS.PendingAdminReview + ";" + RINGI_STATUS.PendingJapan },
                { name: localizedFilterFlyout.status.pendingAdminReview, value: RINGI_STATUS.PendingAdminReview },
                { name: localizedFilterFlyout.status.pendingJapan, value: RINGI_STATUS.PendingJapan},
                { name: localizedFilterFlyout.status.cancelled, value: RINGI_STATUS.Cancelled},
                { name: localizedFilterFlyout.status.paused, value: RINGI_STATUS.Paused},
                { name: localizedFilterFlyout.status.approvedWithConditions, value: RINGI_STATUS.ApprovedWithConditions}
            ]);
        }
        else if(page === FILTER_TYPE.Draft){
            setStatusOptions([
                { name: localizedFilterFlyout.status.draft, value: RINGI_STATUS.Draft },
                { name: localizedFilterFlyout.status.draftError, value: RINGI_STATUS.DraftError }
            ]);
        }
    }, [localizedFilterFlyout, getViewTypeFromPage]);

    const fetchColumns = useCallback(async () => {
        const columns = await fetch(`${fetchColumnsUrl}/${getViewTypeFromPage()}`);
        if (columns) {
            const theColumns = columns.map((column, i) => ({ ...column, order: i, isShown: true }));
            setDefaultColumns(theColumns.map(col => ({ ...col })));

            const selectedColumns = await fetch(`${fetchSelectedColumnsUrl}/${getViewTypeFromPage()}`);
            if (selectedColumns && selectedColumns.length > 0) {
                setIsDefaultColumnState(false);
                for (let i = 0; i < theColumns.length; i++) {
                    const selectedColumn = selectedColumns.find(col => col.id === theColumns[i].id);
                    if (selectedColumn === undefined) {
                        theColumns[i].isShown = false;
                        theColumns[i].order = -1;
                    } else {
                        theColumns[i].order = selectedColumn.order;
                    }
                }
            }
            setColumns(theColumns.sort((colA, colB) => { return colA.order - colB.order }));
        } else {
            setColumns([]);
        }
    }, [fetchColumnsUrl, fetchSelectedColumnsUrl, getViewTypeFromPage]);

    useEffect(() => {
        fetchColumns();
    }, [fetchColumns]);


    const fetchMoreData = async () => {
        setRingiNextPage(rnp => rnp + 1);

        let ringisRetrieved = [];
        let filterToApply = currentFilterApplied;

        filterToApply.nextPage = ringiNextPage + 1;
        filterToApply.pageCount = RINGI_PAGE_COUNT;

        ringisRetrieved = await put(fetchRingisUrl, filterToApply) ?? [];
        if (ringisRetrieved.length < RINGI_PAGE_COUNT) {
            setHasMoreRingis(false);
        }

        setRingis(previousRingis => [...previousRingis, ...ringisRetrieved]);
    }

    const updateFilterFieldValues = (theFilter) => {
        setStatusFieldValue(theFilter?.status ?? "");
        setInitiatorField({ id: theFilter?.initiatorValue ?? "", name: theFilter?.initiatorName ?? "" });
        setCategoryField({ id: theFilter?.categoryValue ?? "", name: theFilter?.categoryName ?? "" });
        setTypeField({ id: theFilter?.typeValue ?? "", name: theFilter?.typeName ?? "" })
        setCostCenterField({ id: theFilter?.costCenterValue ?? "", name: theFilter?.costCenterName ?? "" })
        setStartDateField(theFilter?.startDate ? LocalDateInputFormat(theFilter?.startDate) : "");
        setEndDateField(theFilter?.endDate ? LocalDateInputFormat(theFilter?.endDate) : "");
        setCompletedStartDateField(theFilter?.completedStartDate ? LocalDateInputFormat(theFilter?.completedStartDate) : "");
        setCompletedEndDateField(theFilter?.completedEndDate ? LocalDateInputFormat(theFilter?.completedEndDate) : "");
    }

    async function fetchData() {
        let ringisRetrieved = [];
        let filterToApply = {};

        var existingFilter = await fetch(`ringiFilters/${getViewTypeFromPage()}`);
        if (existingFilter) {
            filterToApply = existingFilter;
            setCurrentFilterApplied(filterToApply);
            updateFilterFieldValues(filterToApply);
        }

        filterToApply.nextPage = 1;
        filterToApply.pageCount = RINGI_PAGE_COUNT;

        ringisRetrieved = await put(fetchRingisUrl, filterToApply) ?? [];

        if (ringisRetrieved.length < RINGI_PAGE_COUNT) {
            setHasMoreRingis(false);
        }

        setRingis(ringisRetrieved);
        await refreshLandingPage();
    }
    useEffect(() => {
        fetchData();
    }, []);

    const refreshAfterFilterApplied = async (theFilter) => {
        setRingis([]);
        setHasMoreRingis(true);
        setRingiNextPage(theFilter.nextPage);

        var startSplitDate = theFilter?.startDate?.split('/');
        if (startSplitDate?.length === 3) {
            theFilter.startDate = `${startSplitDate[2]}-${startSplitDate[0]}-${startSplitDate[1]}`;
        }

        var endSplitDate = theFilter?.endDate?.split('/');
        if (endSplitDate?.length === 3) {
            theFilter.endDate = `${endSplitDate[2]}-${endSplitDate[0]}-${endSplitDate[1]}`;
        }

        var completedStartSplitDate = theFilter?.completedStartDate?.split('/');
        if (completedStartSplitDate?.length === 3) {
            theFilter.completedStartDate = `${completedStartSplitDate[2]}-${completedStartSplitDate[0]}-${completedStartSplitDate[1]}`;
        }

        var completedEndSplitDate = theFilter?.completedEndDate?.split('/');
        if (completedEndSplitDate?.length === 3) {
            theFilter.completedEndDate = `${completedEndSplitDate[2]}-${completedEndSplitDate[0]}-${completedEndSplitDate[1]}`;
        }

        updateFilterFieldValues(theFilter);

        let ringisRetrieved = [];
        let filterToApply = theFilter;

        filterToApply.startDate = LocalDateRangeStartAddUtcOffset(theFilter.startDate);
        filterToApply.endDate = LocalDateRangeEndAddUtcOffset(theFilter.endDate);

        filterToApply.completedStartDate = LocalDateRangeStartAddUtcOffset(theFilter.completedStartDate);
        filterToApply.completedEndDate = LocalDateRangeEndAddUtcOffset(theFilter.completedEndDate);

        ringisRetrieved = await put(fetchRingisUrl, filterToApply) ?? [];
        if (ringisRetrieved.length < RINGI_PAGE_COUNT) {
            setHasMoreRingis(false);
        }

        setRingis(ringisRetrieved);
    }

    const filterIsApplied = () => {
        if (!currentFilterApplied || Object.keys(currentFilterApplied).length === 0) {
            return false;
        }

        //TODO: A more robust implementation should be preferred
        return statusFieldValue != defaultFilterValue ||
            initiatorField != defaultFilterObject ||
            categoryField != defaultFilterObject ||
            typeField != defaultFilterObject ||
            startDateField != defaultFilterValue ||
            endDateField != defaultFilterValue ||
            completedStartDateField != defaultFilterValue ||
            completedEndDateField != defaultFilterValue;
    }

    useEffect(() => {
        if (ringis.length > 0) {
            setEmptyMessageDisplayed('');
        }
        else if (filterIsApplied()) {
            setEmptyMessageDisplayed(localizedViewRingis.empty_message_filtered_ringis);
        }
        else {
            setEmptyMessageDisplayed(emptyMessage);
        }
    }, [ringis, currentFilterApplied])

    const handleRingiFilterChange = (option) => {
        var optionAsInt = parseInt(option);
        switch (optionAsInt) {
            case RINGI_LIST_FILTER_OPTIONS.CurrentRingis:
                history.push(`/ringis/current`);
                break;
            case RINGI_LIST_FILTER_OPTIONS.MyDraftRingis:
                history.push(`/ringis/drafts`);
                break;
            case RINGI_LIST_FILTER_OPTIONS.MySubmittedRingis:
                history.push(`/ringis/submitted`);
                break;
            case RINGI_LIST_FILTER_OPTIONS.RingisToApprove:
                history.push(`/ringis/toApprove`);
                break;
            case RINGI_LIST_FILTER_OPTIONS.RingisToRead:
                history.push(`/ringis/toRead`);
                break;
            case RINGI_LIST_FILTER_OPTIONS.ArchivedRingis:
                history.push(`/ringis/archived`);
                break;
            default:
                throw `Filter ${optionAsInt} not defined`;
        }
    };

    const filterButtonClicked = () => {
        setFlyoutFilterVisible(true);
    };

    const clearAllFilters = () => {
        const filter = getBlankFilter();

        setCurrentFilterApplied(filter);
        updateFilterFieldValues(filter);
    }

    const applyFilter = async () => {

        var filterToApply = await getFormattedFilterData();

        setCurrentFilterApplied(filterToApply);
        await refreshAfterFilterApplied(filterToApply);

        setFlyoutFilterVisible(false);
    }

    const applyAndSaveAsDefaultFilter = async () => {
        var filterToSave = await getFormattedFilterData();

        await put(ROUTES.RINGI_FILTERS_UPDATE, filterToSave);

        setCurrentFilterApplied(filterToSave);
        await refreshAfterFilterApplied(filterToSave);

        setFlyoutFilterVisible(false);
    }

    const getFormattedFilterData = useCallback(async () => {
        return {
            id: currentFilterApplied?.id,
            userId: currentFilterApplied?.userId,
            ringiViewTypeId: getViewTypeFromPage(),
            nextPage: 1,
            pageCount: RINGI_PAGE_COUNT,
            searching: false,
            status: statusFieldValue,
            initiatorValue: initiatorField.id,
            initiatorName: initiatorField.name,
            categoryValue: categoryField.id,
            categoryName: categoryField.name,
            typeValue: typeField.id,
            typeName: typeField.name,
            costCenterValue: costCenterField.id,
            costCenterName: costCenterField.name,
            startDate: startDateField,
            endDate: endDateField,
            completedStartDate: completedStartDateField,
            completedEndDate: completedEndDateField,
            searchDto: currentFilterApplied.search
        };
    }, [currentFilterApplied,
        getViewTypeFromPage,
        statusFieldValue,
        initiatorField,
        categoryField,
        typeField,
        costCenterField,
        startDateField,
        endDateField,
        completedStartDateField,
        completedEndDateField]);

    const getBlankFilter = () => {
        return {
            id: currentFilterApplied?.id,
            userId: currentFilterApplied?.userId,
            ringiViewTypeId: getViewTypeFromPage(),
            nextPage: 1,
            pageCount: RINGI_PAGE_COUNT
        };
    };

    const columnsButtonClicked = () => {
        setFlyoutColumnsVisible(true);
        setManipulatedColumns(columns.map(col => ({ ...col })));
    };

    const moveColumnUp = (column) => {
        if (column.order !== 0) {
            const theColumns = manipulatedColumns.map(col => ({ ...col }));
            theColumns.find(col => col.order === column.order - 1).order = column.order;
            theColumns.find(col => col.key === column.key).order = column.order - 1;
            setManipulatedColumns(theColumns);
            setColumnOrderChanged(true);
            setIsDefaultColumnState(false);
        }
    };

    const moveColumnDown = (column) => {
        if (column.order !== manipulatedColumns.filter(col => col.isShown).length - 1) {
            const theColumns = manipulatedColumns.map(col => ({ ...col }));
            theColumns.find(col => col.order === column.order + 1).order = column.order;
            theColumns.find(col => col.key === column.key).order = column.order + 1;
            setManipulatedColumns(theColumns);
            setColumnOrderChanged(true);
            setIsDefaultColumnState(false);
        }
    };

    const removeColumn = (column) => {
        const theColumns = manipulatedColumns.map(col => ({ ...col }));
        const pos = theColumns.find(col => col.key === column.key).order;
        for (let i = 0; i < theColumns.length; i++) {
            if (theColumns[i].order > pos) {
                theColumns[i].order--;
            }
        }
        theColumns.find(col => col.key === column.key).isShown = false;
        theColumns.find(col => col.key === column.key).order = -1;
        setManipulatedColumns(theColumns);
        setColumnOrderChanged(true);
        setIsDefaultColumnState(false);
    };

    const resetColumns = () => {
        setManipulatedColumns(defaultColumns);
        setColumnOrderChanged(true);
        setIsDefaultColumnState(true);
    };

    const addColumn = () => {
        setFlyoutRemovedColumnsVisible(true);
    };

    const addColumnBack = (column) => {
        const theColumns = manipulatedColumns.map(col => ({ ...col }));
        theColumns.find(c => c.key === column.key).isShown = true;
        const lastIdx = theColumns.filter(col => col.isShown).length - 1;
        theColumns.find(c => c.key === column.key).order = lastIdx;
        setManipulatedColumns(theColumns);
        setFlyoutRemovedColumnsVisible(false);
        setColumnOrderChanged(true);
        setIsDefaultColumnState(false);
    };

    const applyColumns = async () => {
        const theColumns = manipulatedColumns
            .sort((colA, colB) => { return colA.order - colB.order })
            .map(col => ({ ...col }));

        setColumns(theColumns);
        setColumnOrderChanged(false);
        setFlyoutColumnsVisible(false);
    };

    const applyAndSaveAsDefaultColumns = async () => {
        let selectedColumns = {};

        const theColumnsToSave = manipulatedColumns
            .filter(col => col.isShown)
            .sort((colA, colB) => { return colA.order - colB.order })
            .map(col => ({
                id: col.id,
                ringiViewTypeId: getViewTypeFromPage(),
                order: col.order
            }));

        selectedColumns.RingiColumns = theColumnsToSave;

        if (isDefaultColumnState) {
            await httpDelete(`${fetchSelectedColumnsUrl}/${getViewTypeFromPage()}`);
        } else {
            await put(ROUTES.RINGI_COLUMNS_UPDATE, selectedColumns);
        }

        const theColumns = manipulatedColumns
            .sort((colA, colB) => { return colA.order - colB.order })
            .map(col => ({ ...col }));

        setColumns(theColumns);
        setColumnOrderChanged(false);
        setFlyoutColumnsVisible(false);
    }

    const setCurrentPageAsDefault = async () => {
        await put(`${ROUTES.CURRENT_USER_LANDING_PAGE}/update`, JSON.stringify(ROUTE_MAP[defaultSelection]));
        await refreshLandingPage();
    }

    const onSearchRingisSubmit = async (event) => {
        event.preventDefault();

        var filterToApply = getBlankFilter();
        filterToApply.searching = true;
        filterToApply.searchDto = {
            searchField: methods.getValues().ringiSearchInput
        }

        setCurrentFilterApplied(filterToApply);
        await refreshAfterSearchApplied(filterToApply);
    }

    const refreshAfterSearchApplied = async (theFilter) => {
        setRingis([]);
        setHasMoreRingis(true);
        setRingiNextPage(theFilter.nextPage);

        let ringisRetrieved = [];
        let filterToApply = theFilter;

        ringisRetrieved = await put(fetchRingisUrl, filterToApply) ?? [];
        if (ringisRetrieved.length < RINGI_PAGE_COUNT) {
            setHasMoreRingis(false);
        }

        setRingis(ringisRetrieved);
    }

    useEffect(() => {
        const fetchFilterOptions = async () => {
            var options = await put(`${fetchRingisUrl}/filterOptions`);
            setFilterOptions(options);
        }
        fetchFilterOptions();
    }, [fetchRingisUrl])

    const setStatusFilterFieldFlyoutVisibility = (visible) => {
        setFlyoutFilterStatusFieldVisible(visible);
        setFlyoutFilterVisible(!visible);
    }

    const setStatusFieldAndCloseFlyout = (status) => {
        setStatusFieldValue(status);
        setStatusFilterFieldFlyoutVisibility(false);
    }

    const setInitiatorFieldFlyoutVisibility = (visible) => {
        setFlyoutFilterInitiatorIsVisible(visible);
        setFlyoutFilterVisible(!visible);
    }

    const setInitiatorFieldAndCloseFlyout = (initiator) => {
        setInitiatorField(initiator);
        setInitiatorFieldFlyoutVisibility(false);
    }

    const setCategoryFieldFlyoutVisibility = (visible) => {
        setFlyoutFilterCategoryIsVisible(visible);
        setFlyoutFilterVisible(!visible);
    }

    const setCategoryFieldAndCloseFlyout = (category) => {
        setCategoryField(category);
        setCategoryFieldFlyoutVisibility(false);
    }

    const setTypeFieldFlyoutVisibility = (visible) => {
        setFlyoutFilterTypeIsVisible(visible);
        setFlyoutFilterVisible(!visible);
    }

    const setTypeFieldAndCloseFlyout = (type) => {
        setTypeField(type);
        setTypeFieldFlyoutVisibility(false);
    }

    const setCostCenterFieldFlyoutVisibility = (visible) => {
        setFlyoutFilterCostCenterIsVisible(visible);
        setFlyoutFilterVisible(!visible);
    }

    const setCostCenterFieldAndCloseFlyout = (type) => {
        setCostCenterField(type);
        setCostCenterFieldFlyoutVisibility(false);
    }

    const closeAllFilterFlyouts = () => {
        setFlyoutFilterStatusFieldVisible(false);
        setFlyoutFilterInitiatorIsVisible(false);
        setFlyoutFilterCategoryIsVisible(false);
        setFlyoutFilterTypeIsVisible(false);
        setFlyoutFilterCostCenterIsVisible(false);
        setFlyoutFilterVisible(false);
    }

    const onSearchClear = () => {
        fetchData();
    }

    const fetchSortedRingis = async (sortString) => {
        let theFilter = await getFormattedFilterData();
        theFilter.columnSortDto = {
            columnAndSortDirection: sortString
        }
        setRingis([]);
        setHasMoreRingis(true);
        setRingiNextPage(theFilter.nextPage);

        if (sortString.indexOf("CompletedDate") >= 0) {
            theFilter.completedStartDate = LocalDateRangeStartAddUtcOffset(theFilter.completedStartDate);
            theFilter.completedEndDate = LocalDateRangeEndAddUtcOffset(theFilter.completedEndDate);
        }

        if (sortString.indexOf("SubmittedDate") >= 0) {
            theFilter.startDate = LocalDateRangeStartAddUtcOffset(theFilter.startDate);
            theFilter.endDate = LocalDateRangeEndAddUtcOffset(theFilter.endDate);
        }

        let ringisRetrieved = [];
        let filterToApply = theFilter;

        ringisRetrieved = await put(fetchRingisUrl, filterToApply) ?? [];
        if (ringisRetrieved.length < RINGI_PAGE_COUNT) {
            setHasMoreRingis(false);
        }

        setRingis(ringisRetrieved);
        
        setCurrentFilterApplied(filterToApply);
        await refreshAfterSearchApplied(filterToApply);
    }

    return (
        <RingiPage className="view-ringis">
            <PageContent primaryMobileContent className={classNames({ 'ringi-page__content-mobile': isMobile })} >
                <div>
                    <PageHeader className={classNames({ 'view-ringis__page-header-mobile': isMobile })}>
                        <div className={isDesktop ? 'view-ringis-header__container' : 'view-ringis-header__container-mobile'}>
                            <div className="view-ringis__list-selector-container-mobile">
                                <select data-testid="view-ringi-filter-list" className={isDesktop ? 'view-ringis__list-selector' : 'view-ringis__list-selector-mobile'}
                                    onChange={(event) => handleRingiFilterChange(event.target.value)} defaultValue={defaultSelection}>
                                    <option data-testid="view-ringi-filter-current-ringi" value={RINGI_LIST_FILTER_OPTIONS.CurrentRingis}>
                                        {localizedViewRingis.current_ringi_view_option}
                                    </option>
                                    <option data-testid="view-ringi-filter-draft-ringi" value={RINGI_LIST_FILTER_OPTIONS.MyDraftRingis}>
                                        {localizedViewRingis.draft_ringi_view_option}
                                    </option>
                                    <option data-testid="view-ringi-filter-submitted-ringi" value={RINGI_LIST_FILTER_OPTIONS.MySubmittedRingis}>
                                        {localizedViewRingis.submitted_ringi_view_option}
                                    </option>
                                    <option data-testid="view-ringi-filter-to-approve" value={RINGI_LIST_FILTER_OPTIONS.RingisToApprove}>
                                        {localizedViewRingis.ringis_to_approve_option}
                                    </option>
                                    <option data-testid="view-ringi-filter-to-read" value={RINGI_LIST_FILTER_OPTIONS.RingisToRead}>
                                        {localizedViewRingis.ringis_to_read_option}
                                    </option>
                                    <option data-testid="view-ringi-filter-archived" value={RINGI_LIST_FILTER_OPTIONS.ArchivedRingis}>
                                        {localizedViewRingis.archived_ringis_option}
                                    </option>
                                </select>
                                {!currentPageIsDefault && isDesktop &&
                                    <span data-testid='set-default-landing-page-button'>
                                        <Button variant='link' className='default-view-link__container' onClick={setCurrentPageAsDefault}>
                                            {localizedViewRingis.set_default_view}
                                        </Button>
                                    </span>
                                }
                            </div>
                            <div className={isDesktop ? 'view-ringis__search-container' : 'view-ringis__search-container-mobile'}>
                                <FormProvider {...methods}>
                                    <form className="view-ringis__search-functionality" onSubmit={onSearchRingisSubmit}>
                                        <ValidationInput
                                            type="text"
                                            fieldName="ringiSearchInput"
                                            placeholder={localizedSearchRingis.ringi_search_placeholder}
                                            onClear={onSearchClear}
                                        />
                                        <button data-testid="ringiSearchButton" type="submit" className="view-ringis__search-button">
                                            <FontAwesomeIcon icon={faSearch} />
                                        </button>

                                    </form>
                                </FormProvider>
                            </div>

                            <div className={isDesktop ? 'view-ringis__buttons' : 'view-ringis__buttons-mobile'}>
                                {isDesktop &&
                                    <Button id="columns-toggle" data-testid="columns-toggle" className="view-ringis-buttons__columns" onClick={columnsButtonClicked}>
                                        <FontAwesomeIcon icon={faGripLinesVertical} />
                                        {localizedColumnsFlyout.columns_button}
                                    </Button>
                                }
                                <Button id="filter-toggle" data-testid="filter-toggle" className="view-ringis-buttons__filters" onClick={filterButtonClicked}>
                                    <FontAwesomeIcon icon={faFilter} />
                                    {isMobile ? "" : localizedFilterFlyout.filter_button}
                                </Button>
                                <NavLink to={{ pathname: "/ringis/new", state: { ringiListFrom: defaultSelection } }} >
                                    <Button data-testid='create-ringi-button' className="btn-add-ringi">
                                        <FontAwesomeIcon icon={faPlus} />
                                        {isDesktop &&
                                            <span className="btn-add-ringi__text">{`${localizedViewRingis.create_new_ringi_button_text}`}</span>
                                        }
                                    </Button>
                                </NavLink>
                            </div>
                        </div>
                    </PageHeader>
                    <div className={flyoutFilterIsVisible ? 'flyout__overlay' : ""} />
                    <div id="filter-flyout" data-testid="filter-flyout" className={classNames("flyout",
                        { "flyout--active": flyoutFilterIsVisible },
                        { "flyout--mobile": !isDesktop && flyoutFilterIsVisible })}>
                        <div className="flyout__header">
                            <label>
                                {localizedFilterFlyout.title}
                            </label>
                            <div className="flyout__rounded-button" onClick={() => setFlyoutFilterVisible(false)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </div>
                        </div>
                        <div data-testid="filter-flyout-content" className="filter-flyout-content__container">
                            <span>
                                {statusFieldValue !== "" ?
                                    <div className="filter-flyout-content__selected-field">
                                        <div>
                                            {statusOptions.find(s => s.value == statusFieldValue)?.name}
                                        </div>
                                        <span onClick={() => setStatusFieldValue("")}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </span>
                                    </div>
                                    :
                                    <span>
                                        <button className="filter-flyout-input__button" data-testid="filter-flyout-input-status" onClick={() => setStatusFilterFieldFlyoutVisibility(true)} >
                                            {localizedFilterFlyout.status_field}
                                            <span className="filter-flyout-content__arrow" >
                                                <FontAwesomeIcon icon={faArrowRight} />
                                            </span>
                                        </button>
                                    </span>
                                }
                            </span>
                            <span>
                                {initiatorField.name !== "" ?
                                    <div className="filter-flyout-content__selected-field">
                                        <div>
                                            {initiatorField.name}
                                        </div>
                                        <span onClick={() => setInitiatorField(defaultFilterObject)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </span>
                                    </div>
                                    :
                                    <span>
                                        <button className="filter-flyout-input__button" data-testid="filter-flyout-input-initiator" onClick={() => setInitiatorFieldFlyoutVisibility(true)} >
                                            {localizedFilterFlyout.initiator_field}
                                            <span className="filter-flyout-content__arrow" >
                                                <FontAwesomeIcon icon={faArrowRight} />
                                            </span>
                                        </button>
                                    </span>
                                }
                            </span>
                            <span>
                                {categoryField.name !== "" ?
                                    <div className="filter-flyout-content__selected-field">
                                        <div>
                                            {categoryField.name}
                                        </div>
                                        <span onClick={() => setCategoryField(defaultFilterObject)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </span>
                                    </div>
                                    :
                                    <span>
                                        <button className="filter-flyout-input__button" data-testid="filter-flyout-input-category" onClick={() => setCategoryFieldFlyoutVisibility(true)} >
                                            {localizedFilterFlyout.category_field}
                                            <span className="filter-flyout-content__arrow" >
                                                <FontAwesomeIcon icon={faArrowRight} />
                                            </span>
                                        </button>
                                    </span>
                                }
                            </span>
                            <span>
                                {typeField.name !== "" ?
                                    <div className="filter-flyout-content__selected-field">
                                        <div>
                                            {typeField.name}
                                        </div>
                                        <span onClick={() => setTypeField(defaultFilterObject)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </span>
                                    </div>
                                    :
                                    <span>
                                        <button className="filter-flyout-input__button" data-testid="filter-flyout-input-type" onClick={() => setTypeFieldFlyoutVisibility(true)} >
                                            {localizedFilterFlyout.type_field}
                                            <span className="filter-flyout-content__arrow" >
                                                <FontAwesomeIcon icon={faArrowRight} />
                                            </span>
                                        </button>
                                    </span>
                                }
                            </span>
                            <span>
                                {costCenterField.name !== "" ?
                                    <div className="filter-flyout-content__selected-field">
                                        <div>
                                            {costCenterField.name}
                                        </div>
                                        <span onClick={() => setCostCenterField(defaultFilterObject)}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </span>
                                    </div>
                                    :
                                    <span>
                                        <button className="filter-flyout-input__button" data-testid="filter-flyout-input-cost-center" onClick={() => setCostCenterFieldFlyoutVisibility(true)} >
                                            {localizedFilterFlyout.costCenter_field}
                                            <span className="filter-flyout-content__arrow" >
                                                <FontAwesomeIcon icon={faArrowRight} />
                                            </span>
                                        </button>
                                    </span>
                                }
                            </span>
                            <div>
                                <span>
                                    <label>{localizedFilterFlyout.start_date_field}</label>
                                    <input value={startDateField} onChange={e => setStartDateField(e.currentTarget.value)} type="date"></input>
                                </span>
                                <span>
                                    <label>{localizedFilterFlyout.end_date_field}</label>
                                    <input value={endDateField} onChange={e => setEndDateField(e.currentTarget.value)} type="date"></input>
                                </span>
                            </div>
                            {getViewTypeFromPage() === FILTER_TYPE.Archived &&
                                <div>
                                    <span>
                                        <label>{localizedFilterFlyout.completed_start_date_field}</label>
                                        <input value={completedStartDateField} onChange={e => setCompletedStartDateField(e.currentTarget.value)} type="date"></input>
                                    </span>
                                    <span>
                                        <label>{localizedFilterFlyout.completed_end_date_field}</label>
                                        <input value={completedEndDateField} onChange={e => setCompletedEndDateField(e.currentTarget.value)} type="date"></input>
                                    </span>
                                </div>
                            }
                        </div>
                        <div data-testid="filter-flyout-buttons" className="flyout__buttons">
                            <Button data-testid="filter-flyout-button-apply" onClick={applyFilter}>
                                {localizedFilterFlyout.apply_button}
                            </Button>
                            <Button onClick={applyAndSaveAsDefaultFilter}>
                                {localizedFilterFlyout.apply_and_save_button}
                            </Button>

                        </div>
                        <label data-testid="filter-flyout-clear-filters"
                            className="filter-flyout__clear-filters"
                            onClick={clearAllFilters}>
                            {localizedFilterFlyout.clear_all_filters}
                        </label>
                    </div>
                    <div className={flyoutFilterStatusFieldIsVisible ? 'flyout__overlay' : ""} />
                    <div id="filter-flyout-field" data-testid="filter-flyout-field" className={classNames("flyout",
                        { "flyout--active": flyoutFilterStatusFieldIsVisible },
                        { "flyout--mobile": !isDesktop && flyoutFilterStatusFieldIsVisible })}>
                        <div className="flyout__header">
                            <div className="filter-flyout__header">
                                <div className="flyout__rounded-button" onClick={() => setStatusFilterFieldFlyoutVisibility(false)}>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </div>
                                <label>
                                    {localizedFilterFlyout.status_field}
                                </label>
                            </div>
                            <div className="flyout__rounded-button" onClick={closeAllFilterFlyouts}>
                                <FontAwesomeIcon icon={faTimes} />
                            </div>
                        </div>
                        <div data-testid="filter-flyout-content__options" className="filter-flyout-content__container">
                            {statusOptions.map((status, i) => {
                                return (<div key={i}>
                                    <button data-testid={`filter-flyout-status-option`} className="filter-flyout-input__option-button" onClick={e => setStatusFieldAndCloseFlyout(status.value)}>
                                        {status.name}
                                    </button>
                                </div>);
                            })}
                        </div>
                    </div>
                    <FilterFlyoutSearchList
                        label={localizedFilterFlyout.initiator_field}
                        isVisible={flyoutFilterInitiatorIsVisible}
                        setIsVisible={setInitiatorFieldFlyoutVisibility}
                        closeFlyouts={closeAllFilterFlyouts}
                        setSelectedOption={setInitiatorFieldAndCloseFlyout}
                        filterOptions={filterOptions.initiatorOptions}
                    />
                    <FilterFlyoutSearchList
                        label={localizedFilterFlyout.category_field}
                        isVisible={flyoutFilterCategoryIsVisible}
                        setIsVisible={setCategoryFieldFlyoutVisibility}
                        closeFlyouts={closeAllFilterFlyouts}
                        setSelectedOption={setCategoryFieldAndCloseFlyout}
                        filterOptions={filterOptions.categoryOptions}
                    />
                    <FilterFlyoutSearchList
                        label={localizedFilterFlyout.type_field}
                        isVisible={flyoutFilterTypeIsVisible}
                        setIsVisible={setTypeFieldFlyoutVisibility}
                        closeFlyouts={closeAllFilterFlyouts}
                        setSelectedOption={setTypeFieldAndCloseFlyout}
                        filterOptions={filterOptions.typeOptions}
                    />
                    <FilterFlyoutSearchList
                        label={localizedFilterFlyout.costCenter_field}
                        isVisible={flyoutFilterCostCenterIsVisible}
                        setIsVisible={setCostCenterFieldFlyoutVisibility}
                        closeFlyouts={closeAllFilterFlyouts}
                        setSelectedOption={setCostCenterFieldAndCloseFlyout}
                        filterOptions={filterOptions.costCenterOptions}
                    />
                    <div className={flyoutColumnsIsVisible ? 'flyout__overlay' : ""} />
                    <div id="columns-flyout" data-testid="columns-flyout" className={classNames("flyout", { "flyout--active": flyoutColumnsIsVisible })}>
                        <div data-testid="columns-flyout-header" className="flyout__header">
                            <label>
                                {localizedColumnsFlyout.title}
                            </label>
                            <div className="flyout__rounded-button" onClick={() => setFlyoutColumnsVisible(false)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </div>
                        </div>
                        {
                            manipulatedColumns
                                .sort((colA, colB) => { return colA.order - colB.order })
                                .filter(theCol => {
                                    return theCol.isShown === true;
                                })
                                .map((column, i) => {
                                    return (
                                        <div key={i} data-testid={`columns-flyout-content-${column.order}`} className="columns-flyout-content__row">
                                            <div data-testid={`columns-flyout-content-${column.order}__move-up`} className={classNames({ 'grid-child flyout__rounded-button': i !== 0, 'grid-child flyout__rounded-button--disabled': i === 0 })} onClick={() => moveColumnUp(column)}>
                                                <FontAwesomeIcon icon={faArrowUp} />
                                            </div>
                                            <div data-testid={`columns-flyout-content-${column.order}__move-dn`} className={classNames({ 'grid-child flyout__rounded-button': i !== manipulatedColumns.length - 1, 'grid-child flyout__rounded-button--disabled': i === manipulatedColumns.filter(col => col.isShown).length - 1 })} onClick={() => moveColumnDown(column)}>
                                                <FontAwesomeIcon icon={faArrowDown} />
                                            </div>
                                            <div className="grid-child">
                                                <input className="columns-flyout-content__input" data-testid={`columns-flyout-input-${column.order}`} placeholder={column.name} type="text" readOnly={true} ></input>
                                            </div>
                                            <div data-testid={`columns-flyout-content-${column.order}__remove`} className="grid-child columns-flyout-content__x" onClick={() => removeColumn(column)}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </div>
                                        </div>
                                    );
                                })
                        }
                        <div data-testid="columns-flyout-buttons-1" className="flyout__buttons">
                            <Button data-testid="columns-flyout-button-add" className="columns-flyout-buttons__add-column-button" onClick={addColumn} disabled={manipulatedColumns.filter(col => col.isShown === false).length === 0}>
                                <FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;&nbsp;{localizedColumnsFlyout.add_button}
                            </Button>
                        </div>
                        <div data-testid="columns-flyout-buttons-2" className="flyout__buttons-centered">
                            <Button variant="link" data-testid="columns-flyout-button-reset" className="columns-flyout-buttons__reset-columns-button" onClick={resetColumns} disabled={isDefaultColumnState}>
                                {localizedColumnsFlyout.reset_columns_button}
                            </Button>
                        </div>
                        <div data-testid="columns-flyout-buttons-3" className="flyout__buttons">
                            <Button data-testid="columns-flyout-button-apply" onClick={applyColumns} disabled={!columnOrderChanged}>
                                {localizedColumnsFlyout.apply_button}
                            </Button>
                            <Button data-testid="columns-flyout-button-apply-and-save" onClick={applyAndSaveAsDefaultColumns}>
                                {localizedColumnsFlyout.apply_and_save_button}
                            </Button>
                        </div>
                    </div>
                    <div className={flyoutRemovedColumnsIsVisible ? 'flyout__overlay' : ""} />
                    <div id="removed-columns-flyout" data-testid="removed-columns-flyout" className={classNames("flyout", { "flyout--active": flyoutRemovedColumnsIsVisible })}>
                        <div data-testid="removed-columns-flyout-header" className="flyout__header">
                            <label>
                                {localizedColumnsFlyout.title}
                            </label>
                            <div className="flyout__rounded-button" onClick={() => setFlyoutRemovedColumnsVisible(false)}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </div>
                        </div>
                        {
                            manipulatedColumns
                                .filter(theCol => {
                                    return theCol.isShown === false;
                                })
                                .map((column, i) => {
                                    return (
                                        <div key={i} data-testid={`removed-columns-flyout-content-${column.key}`} className="removed-columns-flyout-content__row" onClick={() => addColumnBack(column)}>
                                            <div className="grid-child">
                                                <input className="removed-columns-flyout-content__input" data-testid={`removed-columns-flyout-input-${column.key}`} placeholder={column.name} type="text" readOnly={true} ></input>
                                            </div>
                                        </div>
                                    );
                                })
                        }
                    </div>
                </div>
                <div className={classNames({ 'view-ringis__list-container-mobile': isMobile })}>
                    {!currentPageIsDefault && isMobile &&
                        <div data-testid='set-default-landing-page-button' className="default-view-link__outer-container-mobile">
                            <Button variant='link' className='default-view-link__container-mobile' onClick={setCurrentPageAsDefault}>
                                {localizedViewRingis.set_default_view}
                            </Button>
                        </div>
                    }
                    <InfiniteScroll
                        dataLength={ringis.length}
                        next={fetchMoreData}
                        hasMore={hasMoreRingis}
                        scrollableTarget="ringiPageContent">
                        <RingiList
                            ringis={ringis}
                            columns={columns}
                            emptyMessage={emptyMessageDisplayed}
                            onSortOrderChanged={fetchSortedRingis} />
                    </InfiniteScroll>
                </div>
            </PageContent>
        </RingiPage>
    );
}