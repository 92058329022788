import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fetch } from 'services/api';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocale } from 'services/localization/localizationContextProvider';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import { requiredRule, alphaNumericRule } from 'services/validationRules';
import { uniqueRule } from 'services/serverValidationRules';
import ValidationTypeahead from 'controls/ValidationTypeahead/ValidationTypeahead';
import { GeneralError } from 'components/ValidationError';
import 'pages/Configuration/Category/components/CategoryEditor.scss';

export default function CategoryEditor({ title, category, editTitleOnly, className, onSave, onClose }) {
    const localized = useLocale();
    const methods = useForm({ defaultValues: { categoryName: category?.name }, reValidateMode: 'onSubmit' });
    const [hasPermissionsForUsersSelected, setHasPermissionsForUsersSelected] = useState(false);
    const [hasPermissionsForUsersChanged, setHasPermissionsForUsersChanged] = useState(false);
    const [userList, setUserList] = useState([]);
    const [categoryUsersList, setCategoryUsersList] = useState([]);
    const [generalErrorMessage, setGeneralErrorMessage] = useState();

    useEffect(() => {
        const fetchUsers = async () => {
            const users = await fetch(`users/active`);
            const usersWithShowFlag = users.map(user => ({ ...user, showFlag: true }));

            if (editTitleOnly === false && category.layerUsers.length > 0) {
                const categoryUsers = category.layerUsers.map(layerUser => ({
                    id: layerUser.user.id,
                    fullName: layerUser.user.fullName
                }));
                setHasPermissionsForUsersSelected(true);
                setCategoryUsersList(categoryUsers);

                categoryUsers.forEach(u => {
                    const userOption = usersWithShowFlag.find(option => option.id === u.id);
                    if (userOption) {
                        userOption.showFlag = false;
                    }
                });
            }

            setUserList(usersWithShowFlag);
        };
        fetchUsers();

        setGeneralErrorMessage("");
    }, []);

    const handleCancelAddNewCategory = () => {
        onClose();
    };

    const handleHasPermissionsForUsersSelectedChanged = (isChecked) => {
        setHasPermissionsForUsersSelected(isChecked);
        setHasPermissionsForUsersChanged(true);

        if (isChecked === false) {
            setCategoryUsersList([]);
            const updatedUserList = userList.map(user => ({ ...user, showFlag: true }));
            setUserList(updatedUserList);
        }
    };

    const onSubmit = async (formData) => {
        if (generalErrorMessage === "") {
            await onSave(formData["categoryName"], categoryUsersList, category?.id, hasPermissionsForUsersChanged);
            onClose();
        }
    };

    const handlePreSubmit = async () => {
        if (hasPermissionsForUsersSelected === true && categoryUsersList.length === 0) {
            setGeneralErrorMessage(localized.category_list.one_or_more_users_required);
        }
        else {
            setGeneralErrorMessage("");
        }
    };

    const handleCancelUserPermission = () => {
        setHasPermissionsForUsersSelected(false);
        setCategoryUsersList([]);
        userList.map(user => user.showFlag = true);
    };

    const handleAddUserPermission = () => {
        const categoryUserId = methods.getValues("categoryUser");

        if (categoryUserId) {
            setGeneralErrorMessage("");

            const categoryUserFullName = userList.find(option => option.id === categoryUserId).fullName;
            setCategoryUsersList([...categoryUsersList, { id: categoryUserId, fullName: categoryUserFullName }]);

            userList.find(option => option.id === categoryUserId).showFlag = false;

            methods.setValue("categoryUser", "");

            setHasPermissionsForUsersChanged(true);
        }
    };

    const handleRemoveUserPermission = (userId) => {
        const filteredUsers = categoryUsersList.filter(option => option.id !== userId);
        setCategoryUsersList(filteredUsers);

        const updatedUserList = userList.map(user =>
            user.id === userId ? { ...user, showFlag: true } : user
        );
        setUserList(updatedUserList);

        setHasPermissionsForUsersChanged(true);
    };

    return (
        <FormProvider {...methods}>
            <form data-testid="category-editor-form" onSubmit={methods.handleSubmit(onSubmit)} className={className}>
                {(category === undefined || editTitleOnly === true) ?
                    <ValidationInput
                        autoFocus
                        type="text"
                        title={title}
                        className="category-editor__input"
                        maxLength={100}
                        minLength={1}
                        fieldName="categoryName"
                        validationRules={
                            {
                                required: requiredRule,
                                pattern: alphaNumericRule,
                                validate: async (categoryName) => await uniqueRule(`categories?name=${categoryName}`, category?.id)
                            }}
                    />
                    :
                    <div className="category-editor__name-label">{category?.name}</div>
                }
                {(category === undefined || editTitleOnly === false) &&
                    <div className="category-editor__permissions">
                        <label data-testid="setPermissionsForUsers-label" className="category-editor__permissions-label">
                            <input
                                type="checkbox"
                                fieldname="hasPermissionsForUsers"
                                id="hasPermissionsForUsers"
                                data-testid="setPermissionsForUsers-checkbox"
                                onChange={e => handleHasPermissionsForUsersSelectedChanged(e.currentTarget.checked)}
                                checked={hasPermissionsForUsersSelected}>
                            </input>
                            {localized.category_list.set_permissions_for_users}
                        </label>
                        {hasPermissionsForUsersSelected &&
                            <div data-testid="category-editor-permissions-users-card" className="category-editor__permissions-users-card">
                                <GeneralError errorMessage={generalErrorMessage} className="draft-ringi-page__icon" />
                                {categoryUsersList.map((user, i) => {
                                    return (
                                        <div key={i} className="category-editor__permissions-user-tab" data-testid={`user-tab-${i}`}>
                                            <div data-testid={`user-tab-full-name-${i}`} className="user-tab__form">
                                                {user.fullName}
                                            </div>
                                            <div className="category-editor__permissions-user-tab-icons">
                                                <FontAwesomeIcon data-testid="user-tab-delete" className="user-tab__delete" icon={faTimes} onClick={() => handleRemoveUserPermission(user.id)} />
                                            </div>
                                        </div>
                                    );
                                })}

                                <div data-testid="category-editor-permissions-users-card-users" className="category-editor__permissions-users-card-users">
                                    <ValidationTypeahead
                                        fieldName="categoryUser"
                                        options={userList.filter(option => option.showFlag === true)}
                                        title={localized.category_list.select_user_to_view_category}
                                        labelKey={option => `${option.firstName} ${option.lastName}, ${option.emailAddress}`}
                                        filterBy={["firstName", "lastName", "emailAddress"]}
                                        emptyLabel={localized.category_list.no_users_available} />
                                </div>
                                <div data-testid="category-editor-permissions-users-card-buttons" className="category-editor__permissions-users-card-buttons">
                                    <Button variant="link" data-testid="category-editor-permissions-cancel" onClick={handleCancelUserPermission}>{localized.buttons.cancel}</Button>
                                    <Button variant="primary" data-testid="category-editor-permissions-add" className="category-editor__permissions-add" onClick={handleAddUserPermission} >
                                        <FontAwesomeIcon icon={faPlus} /> &nbsp; {localized.buttons.add}
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className="category-editor__button-container">
                    <Button variant="link" data-testid="category-editor-cancel" onClick={handleCancelAddNewCategory}>{localized.buttons.cancel}</Button>
                    <Button type="submit" data-testid="category-editor-save" className="category-editor__save" onClick={handlePreSubmit} >
                        <FontAwesomeIcon icon={faSave} /> &nbsp; {localized.buttons.save}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
}