import React, { useState, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ValidationTextArea from 'controls/ValidationTextArea/ValidationTextArea';
import ValidationError from 'components/ValidationError';
import ValidationInput from 'controls/ValidationInput/ValidationInput';
import ValidationSelect from 'controls/ValidationSelect/ValidationSelect';
import ValidationFileInput from 'controls/ValidationFileInput/ValidationFileInput';
import ValidationOptionList from 'controls/ValidationOptionList/ValidationOptionList';
import { useConfigurationPage } from 'pages/Configuration/providers/configurationPageContextProvider';
import { requiredRule, alphaNumericSymbolRule } from 'services/validationRules';
import { uniqueRule } from 'services/serverValidationRules';
import { useLocale } from 'services/localization/localizationContextProvider';
import { ATTACHMENT_TYPES, FIELD_TYPE } from 'constants/enums';
import 'pages/Configuration/Editors/TemplateFieldEditor.scss';

export default ({ templateField, headerText, onCancel, onSave }) => {
    const localized = useLocale();
    const splitOptionsTemplateField = useMemo(function splitOptionsForBoolean() {
        var defaults = JSON.parse(JSON.stringify(templateField));
        if (templateField.attachmentName) {
            defaults.attachedTemplate = { id: 1, name: templateField.attachmentName, storageId: templateField.storageId };
        }
        if (templateField.showOptionsAssociatedWithDependentFields === true) {
            defaults.optionAssociatedWithChildFields = templateField.booleanOptions[0].id;

            if (templateField.childTemplateFieldsAssociatedToOptionA !== undefined &&
                templateField.childTemplateFieldsAssociatedToOptionA !== null &&
                templateField.childTemplateFieldsAssociatedToOptionA === false) {
                defaults.optionAssociatedWithChildFields = templateField.booleanOptions[1].id;
            }
        }
        return defaults;
    }, [templateField]);

    const methods = useForm({ defaultValues: splitOptionsTemplateField, reValidateMode: 'onSubmit' });
    const watchFieldTypeId = methods.watch("fieldTypeId");
    const { fieldTypes } = useConfigurationPage();
    const [isDropDownField, setIsDropDownField] = useState(false);
    const [isBooleanField, setIsBooleanField] = useState(false);
    const [isAttachmentField, setIsAttachmentField] = useState(false);
    const [defaultCheckboxSelected, setDefaultCheckboxSelected] = useState(false);
    const [isFilterableFieldSelected, setIsFilterableFieldSelected] = useState(false);
    const [isRingiViewFieldSelected, setIsRingiViewFieldSelected] = useState(false);
    const [defaultOptionIndex, setDefaultOptionIndex] = useState();
    const [hasTooltipDescriptionSelected, setHasTooltipDescriptionSelected] = useState(templateField.showTooltip);

    const onSubmit = async (templateFieldFromForm) => {
        templateFieldFromForm.id = templateField.id;
        templateFieldFromForm.optionAIsDefault = defaultCheckboxSelected;
        templateFieldFromForm.isFilterableField = isFilterableFieldSelected;
        templateFieldFromForm.isRingiViewField = isRingiViewFieldSelected;
        if (isDropDownField) {
            let theOptions = "";
            for (let i = 0; i < templateFieldFromForm.options.split(';').length; i++) {
                theOptions += templateFieldFromForm[`options-option-${i}`];
                if (i < templateFieldFromForm.options.split(';').length - 1) {
                    theOptions += ";";
                }
            }
            templateFieldFromForm.options = theOptions;
            templateFieldFromForm.defaultOptionIndex = defaultOptionIndex !== undefined ? defaultOptionIndex : -1;
        }
        await onSave(templateFieldFromForm);
    };

    useEffect(() => {
        setDefaultCheckboxSelected(templateField.optionAIsDefault);
        setIsFilterableFieldSelected(templateField.isFilterableField);
        setIsRingiViewFieldSelected(templateField.isRingiViewField);
        setDefaultOptionIndex(templateField.defaultOptionIndex);
    }, [templateField]);

    useEffect(() => {
        const fieldTypeIdAsInt = parseInt(watchFieldTypeId)
        setIsDropDownField(fieldTypeIdAsInt === FIELD_TYPE.DropDown);
        setIsBooleanField(fieldTypeIdAsInt === FIELD_TYPE.Boolean);
        setIsAttachmentField(fieldTypeIdAsInt === FIELD_TYPE.Attachment);
    }, [watchFieldTypeId]);

    return (
        <FormProvider {...methods}>

            <div className="template-field-header">

                <span>{headerText}</span>
                <FontAwesomeIcon className="template-field-header__cancel" icon={faTimes} onClick={() => { onCancel(templateField.id); }} />

            </div>

            <div className="template-field-form">
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    {templateField.parentTemplateFieldId &&
                        <div data-testid="parent-template-field-option-container" className="data-entry-container field-data">
                            <label htmlFor="optionAssociatedWithChildFields" className="data-entry-field-group__label">Display If Parent Field Is*</label>
                            <div className="data-entry-field-group form-field-container_half-width">
                                {templateField.showOptionsAssociatedWithDependentFields === true ?
                                    <ValidationSelect
                                        fieldName="optionAssociatedWithChildFields"
                                        data-testid="data-entry-field-group__select"
                                        className="data-entry-field-group__select"
                                        options={templateField.booleanOptions}
                                        validationRules={{
                                            required: requiredRule
                                        }}
                                    />
                                    :
                                    <div data-testid="option-associated-label" className="option-associated-label">
                                        {templateField.optionAssociatedWithChildFields}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className="data-entry-container">
                        <div className="data-entry-field-group">
                            <label htmlFor="fieldname" className="data-entry-field-group__label">Field Name Title*</label>
                            <ValidationInput
                                autoFocus
                                type="text"
                                minLength={1}
                                maxLength={50}
                                fieldName="fieldName"
                                className="data-entry-field-group__input"
                                validationRules={{
                                    required: requiredRule,
                                    validate: async (fieldName) => await uniqueRule(`layers/${templateField.layerId}/fields?name=${fieldName}`, templateField.id),
                                    pattern: alphaNumericSymbolRule,
                                }}
                            />
                        </div>
                    </div>
                    <div className="data-entry-container field-data">
                        <div className="data-entry-field-group form-field-container_half-width">
                            <label htmlFor="fieldTypeId" className="data-entry-field-group__label">Type*</label>
                            <ValidationSelect
                                fieldName="fieldTypeId"
                                className="data-entry-field-group__select"
                                options={fieldTypes}
                                validationRules={{
                                    required: requiredRule
                                }}
                            />
                        </div>
                        <div className="data-entry-field-group form-field-container_half-width">
                            <label htmlFor="isRequired" className="data-entry-field-group__label">Required*</label>
                            <ValidationSelect
                                fieldName="isRequired"
                                className="data-entry-field-group__select"
                                options={[{ id: true, name: "Yes" }, { id: false, name: "No" }]}
                            />
                        </div>
                    </div>
                    {
                        isDropDownField &&
                        <div className="data-entry-container">
                            <div className="data-entry-field-group">
                                <ValidationOptionList
                                    fieldName="options"
                                    onDefaultOptionIndexChanged={idx => setDefaultOptionIndex(idx)}
                                />
                            </div>
                        </div>
                    }
                    {
                        isBooleanField &&
                        <div className="data-entry-container">
                            <div className="data-entry-field-group">
                                <div className="data-entry-option-a__label">
                                    <label htmlFor="optionA" className="data-entry-field-group__label">Option A*</label>
                                    <input
                                        type="checkbox"
                                        id="optionAIsDefault"
                                        data-testid="optionA-checkbox"
                                        onChange={e => setDefaultCheckboxSelected(e.currentTarget.checked)}
                                        checked={defaultCheckboxSelected}></input>
                                    <span>{localized.configuration_page.type_template_field_editor.default_option_label}</span>
                                </div>
                                <ValidationInput
                                    type="text"
                                    id="optionA"
                                    minLength={1}
                                    maxLength={50}
                                    fieldName="optionA"
                                    className="data-entry-field-group__input"
                                    validationRules={{
                                        required: requiredRule,
                                        validate: value => value !== methods.getValues("optionB") || localized['fields_must_not_match'],
                                        pattern: alphaNumericSymbolRule
                                    }}
                                />
                            </div>
                            <div className="data-entry-field-group">
                                <label htmlFor="optionB" className="data-entry-field-group__label">Option B*</label>
                                <ValidationInput
                                    type="text"
                                    id="optionB"
                                    minLength={1}
                                    maxLength={50}
                                    fieldName="optionB"
                                    className="data-entry-field-group__input"
                                    validationRules={{
                                        required: requiredRule,
                                        validate: value => value !== methods.getValues("optionA") || localized['fields_must_not_match'],
                                        pattern: alphaNumericSymbolRule
                                    }}
                                />
                            </div>
                        </div>
                    }
                    {
                        isAttachmentField &&
                        <div className="data-entry-container">
                            <div className="data-entry-field-group template-field__file-input">
                                <ValidationFileInput
                                    fieldName="attachedTemplate"
                                    attachmentType={ATTACHMENT_TYPES.TemplateField}
                                    validationRules={{
                                        validate: { attachmentRequired: (file) => file != null || localized.validation_messages.required }
                                    }} />
                                <ValidationError fieldName="attachedTemplate" className="template-field-file-input__icon" inline />
                            </div>
                        </div>
                    }
                    {/*{*/}
                    {/*    templateField.layerId === 1 &&*/}
                    {/*    <div data-testid="data-entry-container--base-field-options" classname="data-entry-container field-data--column">*/}
                    {/*        <div>*/}
                    {/*            <input*/}
                    {/*                type="checkbox"*/}
                    {/*                fieldname="isFilterableField"*/}
                    {/*                id="isFilterableField"*/}
                    {/*                data-testid="isFilterableField-checkbox"*/}
                    {/*                onChange={e => setIsFilterableFieldSelected(e.currentTarget.checked)}*/}
                    {/*                checked={isFilterableFieldSelected}>*/}
                    {/*            </input>*/}
                    {/*            <label htmlFor="isFilterableField" className="data-entry-field-group__checkboxLabel">Filterable Field</label>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <input*/}
                    {/*                type="checkbox"*/}
                    {/*                fieldname="isRingiViewField"*/}
                    {/*                id="isRingiViewField"*/}
                    {/*                data-testid="isRingiViewField-checkbox"*/}
                    {/*                onChange={e => setIsRingiViewFieldSelected(e.currentTarget.checked)}*/}
                    {/*                checked={isRingiViewFieldSelected}>*/}
                    {/*            </input>*/}
                    {/*            <label htmlFor="isRingiViewField" className="data-entry-field-group__checkboxLabel">Ability To Add Field as Column</label>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {!isAttachmentField &&
                        <div className="data-entry-container field-data--column">
                            <div>
                                <input
                                    type="checkbox"
                                    {...methods.register("showTooltip")}
                                    id="showTooltip"
                                    data-testid="hasTooltipDescription-checkbox"
                                    onChange={e => setHasTooltipDescriptionSelected(e.currentTarget.checked)}
                                    checked={hasTooltipDescriptionSelected}>
                                </input>
                                <label htmlFor="showTooltip" className="data-entry-field-group__checkboxLabel">Add Field Tooltip/Description</label>
                                <div className="data-entry-field-tooltip__textarea">
                                    <ValidationTextArea
                                        title=""
                                        fieldName="tooltipDescription"
                                        maxLength={200}
                                        placeholderText="Enter tooltip/description"
                                        hideControl={hasTooltipDescriptionSelected === false}
                                        validationRules={{
                                            required: { value: hasTooltipDescriptionSelected, message: "Required" }
                                        }} />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="data-entry-container field-data">
                        <div className="template-field-form__buttons">
                            <Button variant="link" onClick={() => onCancel(templateField.id)}>
                                Cancel
                            </Button>
                            <Button type="submit">
                                <FontAwesomeIcon icon={faSave} /> &nbsp; Save
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </FormProvider>
    );
}